import { generate } from "random-words";

async function makeKeys(password) {
  const keyData = new TextEncoder().encode(password);
  const rawKey = await window.crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'PBKDF2' },
    false,
    ['deriveKey']
  );
  const derivedKey = await window.crypto.subtle.deriveKey(
    { name: 'PBKDF2', salt: keyData, iterations: 1000, hash: 'SHA-256' },
    rawKey,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt']
  );
  const algorithm = { name: 'AES-GCM', iv: keyData };
  return { algorithm, key: derivedKey };
}

export async function encryptMessage(message, password) {
  const data = typeof message === 'string' ? new TextEncoder().encode(message) : message;
  const { algorithm, key } = await makeKeys(password);
  return await window.crypto.subtle.encrypt(algorithm, key, data);
}

export async function decryptMessage(message, password) {
  const data = typeof message === 'string' ? Uint8Array.from(message, c => c.charCodeAt(0)) : message;
  const { algorithm, key } = await makeKeys(password);
  return await window.crypto.subtle.decrypt(algorithm, key, data);
}

function generateRandomFileName() {
  const randomWord = generate({ minLength: 4, maxLength: 10 });
  const randomNumber = generateRandomNDigitNumberString(4);
  return `${randomWord}_${randomNumber}`;
}

function generateRandomNDigitNumberString(numberOfDigits) {
  if (!Number.isInteger(numberOfDigits) || numberOfDigits <= 0) {
    throw new RangeError('numberOfDigits must be a positive integer');
  }
  const maxRandomNumber = Math.pow(10, numberOfDigits);
  const randomNumber = Math.floor(Math.random() * maxRandomNumber);
  return randomNumber.toString().padStart(numberOfDigits, '0');
}

export async function encryptFile(originalFileName, fileContents, password, newFileName = '') {
  const FILENAME_MAX_BYTELENGTH = 255;

  if (!originalFileName) {
    throw new Error('Original file name must not be empty');
  }

  const fileNameBytes = new TextEncoder().encode(originalFileName);
  const fileNameBytesLength = fileNameBytes.byteLength;
  if (fileNameBytesLength > FILENAME_MAX_BYTELENGTH) {
    throw new Error('File name is too long');
  }

  const fileNameLengthTypedArray = new Uint8Array([fileNameBytesLength]);

  const totalLength = fileNameLengthTypedArray.byteLength + fileNameBytesLength + fileContents.byteLength;
  const wrappedFileContents = new Uint8Array(totalLength);
  wrappedFileContents.set(fileNameLengthTypedArray, 0);
  wrappedFileContents.set(fileNameBytes, fileNameLengthTypedArray.byteLength);
  wrappedFileContents.set(fileContents, fileNameLengthTypedArray.byteLength + fileNameBytesLength);

  const encryptedFileContents = await encryptMessage(wrappedFileContents, password);

  if (!newFileName) {
    newFileName = generateRandomFileName();
  }

  return { encryptedFileContents, newFileName };
}

export async function decryptFile(encryptedFileContents, password) {
  const decrypted = await decryptMessage(encryptedFileContents, password);
  if (decrypted.byteLength < Uint8Array.BYTES_PER_ELEMENT) {
    throw new Error('Decrypted content is too short to contain proper file name length data.');
  }

  const fileNameLength = new Uint8Array(decrypted, 0, Uint8Array.BYTES_PER_ELEMENT)[0];
  if (decrypted.byteLength < Uint8Array.BYTES_PER_ELEMENT + fileNameLength) {
    throw new Error('Decrypted content is too short to contain a valid file name.');
  }

  const originalFileName = new TextDecoder().decode(new Uint8Array(decrypted, Uint8Array.BYTES_PER_ELEMENT, fileNameLength));
  const decryptedFileContents = new Uint8Array(decrypted, Uint8Array.BYTES_PER_ELEMENT + fileNameLength);

  return { originalFileName, decryptedFileContents };
}
