import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { encryptMessage, decryptMessage, encryptFile, decryptFile } from '../services/CryptoService';
import Linkify from 'linkify-react';
import ClipboardJS from 'clipboard';
import { isSafari, isMobileSafari } from 'react-device-detect';

function EncryptDecryptForm() {
  const { t } = useTranslation();
  const [password, setPassword] = useState(localStorage.getItem('password') || '');
  const [message, setMessage] = useState('');
  const [encryptedMessage, setEncryptedMessage] = useState('');
  const [decryptedMessage, setDecryptedMessage] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const toBase64 = (message) => window.btoa(String.fromCharCode(...new Uint8Array(message)));
  const fromBase64 = (message) => window.atob(message);

  const handleMessageEncrypt = async () => {
    if(!message) {
      return;
    }

    const encryptMessageAndBase64 = async () => {
      const result = toBase64(await encryptMessage(message, password))
      setEncryptedMessage(result);
      return result;
    };

    if(isSafari || isMobileSafari) {
      // Hack for Safari because on Safari `navigator.clipboard.write` has to be the FIRST asynchronous function to be called: https://stackoverflow.com/questions/66312944/javascript-clipboard-api-write-does-not-work-in-safari
      // And
      const makeBase64EncryptedMessagePromise = async () => {
        const base64Encrypted = await encryptMessageAndBase64();
        return new Blob([base64Encrypted], { type: 'text/plain' });
      };
      navigator.clipboard.write([new ClipboardItem({ 'text/plain': makeBase64EncryptedMessagePromise() })])
        .then(function () { setError(null); })
        .catch(function (err) { setError(`${t('encryptionError')}: ${err.message}`); });
    } else {
      // We cannot use navigator.clipboard due to:
      // calling navigator.clipboard API within Android Webview (e.g. within Wechat app) yields "Write permission denied": https://bugs.chromium.org/p/chromium/issues/detail?id=1271620 and https://zhuanlan.zhihu.com/p/597944027
      try {
        const base64Encrypted = await encryptMessageAndBase64();
        ClipboardJS.copy(base64Encrypted);
        setError(null);
      } catch (err) {
        setError(`${t('encryptionError')}: ${err.message}`);
      }
    }
  };

  const handleMessageDecrypt = async () => {
    if(!message) {
      return;
    }
    try {
      const decrypted = await decryptMessage(fromBase64(message), password);
      setDecryptedMessage(new TextDecoder().decode(decrypted));
      setError(null);
    } catch (err) {
      handleDecryptionError(err);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileEncrypt = async () => {
    if(!file) {
      setError(`${t('encryptionError')}: ${t('fileNotSelectedError')}`);
      return;
    }
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const fileContents = new Uint8Array(event.target.result)
        const { encryptedFileContents, newFileName } = await encryptFile(file.name, fileContents, password);
        const blob = new Blob([encryptedFileContents], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = newFileName;
        link.click();
        setError(null);
      } catch (err) {
        setError(`${t('encryptionError')}: ${err.message}`);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileDecrypt = async () => {
    if(!file) {
      setError(`${t('decryptionUnknownError')}: ${t('fileNotSelectedError')}`);
      return;
    }
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const encryptedFileContents = new Uint8Array(event.target.result);
        const { originalFileName, decryptedFileContents } = await decryptFile(encryptedFileContents, password);
        const blob = new Blob([decryptedFileContents], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = originalFileName;
        link.click();
        setError(null);
      } catch (err) {
        handleDecryptionError(err);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDecryptionError = (err) => {
    if (err.name === 'OperationError') {
      setError(t('invalidPasswordOrNotValidEncryptedFile'));
    } else if (err.name === 'InvalidCharacterError') {
      setError(t('corruptedMessageError'));
    } else {
      setError(`${t('decryptionUnknownError')}: ${err.message}`);
    }
  };

  const linkifyOptions = {
    rel: "noopener noreferrer",
    target: "_blank",
  };

  return (
    <div id="encryptDecryptForm">
      <input id="password" type="password" autoComplete="current-password" value={password} onChange={e => {
        setPassword(e.target.value);
        localStorage.setItem('password', e.target.value);
      }} placeholder={t('passwordPlaceholder')} />
      <textarea id="message" value={message} onChange={e => setMessage(e.target.value)} placeholder={t('messagePlaceholder')} />
      <button onClick={handleMessageEncrypt}>{t('encryptMessageAndCopyToClipboard')}</button>
      {encryptedMessage && (
        <div>
          <p>{t('encryptedMessage')}:</p>
          <pre>{encryptedMessage}</pre>
        </div>
      )}
      <button onClick={handleMessageDecrypt}>{t('decryptMessage')}</button>
      {decryptedMessage && (
        <div>
          <p>{t('decryptedMessage')}:</p>
          <Linkify as="pre" options={linkifyOptions}>{decryptedMessage}</Linkify>
        </div>
      )}

      {error && <p><span className="error">{error}</span></p>}
      <hr className="neon-green" />
      <input id="file" type="file" onChange={handleFileChange} />
      <button onClick={handleFileEncrypt}>{t('encryptSelectedFile')}</button>
      <button onClick={handleFileDecrypt}>{t('decryptSelectedFile')}</button>
    </div>
  );

}

export default EncryptDecryptForm;
