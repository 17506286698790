import React from 'react';
import { useTranslation } from 'react-i18next';
import EncryptDecryptForm from './components/EncryptDecryptForm';
import './App.css';

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <h1>{t('title')}</h1>
      </header>
      <EncryptDecryptForm />
    </div>
  );
}

export default App;
